import Illustration1 from "./illustration1.svg";
import Illustration2 from "./illustration2.svg";
import Illustration3 from "./illustration3.svg";
import Illustration4 from "./illustration4.svg";
import { ReactComponent as Quadrant } from "./quadrant.svg";

export const Illustrations = {
  Illustration1,
  Illustration2,
  Illustration3,
  Illustration4,
  Quadrant,
};
