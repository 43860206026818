import React from 'react';
import "./App.css";
import { Routings } from "./routes";

function App() {
  return (
    <>
      <Routings />
    </>
  );
}

export default App;
